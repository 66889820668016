body {
  margin: 0;
  padding: 0;
  font-family: 'Playfair Display', sans-serif;
  width: 100vw;
  overflow-x: hidden;


}

#root{
  margin: 0;
  padding: 0;
  width: 100vw;
}
