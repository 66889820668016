body {
  margin: 0;
  padding: 0;
  font-family: 'Playfair Display', sans-serif;
  width: 100vw;
  overflow-x: hidden;


}

#root{
  margin: 0;
  padding: 0;
  width: 100vw;
}

#nav{
    width:100%;
    margin:0;
    padding: 0;
    background-color:red;
    
  }
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

#main{
  width:100%;
  margin:0;
  padding: 0;
  
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

